const ClearIcon = ({color}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="6.2"
        fill="#D5E2E7"
        stroke={color}
      />
      <g clip-path="url(#clip0_1399_1574)">
        <path
          d="M22.3242 10.8472L19.7417 15.3115L17.1277 16.0119C17.0481 16.0327 16.9734 16.0691 16.9079 16.1188C16.8424 16.1686 16.7874 16.2308 16.7459 16.3018L12.3265 23.9565C12.285 24.0278 12.2579 24.1066 12.247 24.1884C12.2361 24.2701 12.2414 24.3533 12.2628 24.433C12.2841 24.5127 12.3211 24.5873 12.3714 24.6527C12.4218 24.7181 12.4846 24.7728 12.5562 24.8138L19.4454 28.7912C19.5167 28.8328 19.5955 28.8598 19.6773 28.8707C19.7591 28.8817 19.8422 28.8763 19.9219 28.855C20.0016 28.8336 20.0763 28.7967 20.1416 28.7463C20.207 28.696 20.2617 28.6331 20.3027 28.5615L24.7221 20.9069C24.7629 20.8355 24.7893 20.7567 24.7996 20.6751C24.8099 20.5935 24.8041 20.5107 24.7823 20.4313L24.0803 17.8113L26.6628 13.347C26.9945 12.7724 27.0844 12.0897 26.9127 11.4489C26.741 10.8081 26.3218 10.2617 25.7472 9.93001C25.1727 9.59831 24.4899 9.50842 23.8491 9.68012C23.2083 9.85182 22.662 10.2711 22.3303 10.8456L22.3242 10.8472ZM18.314 26.6938L19.3321 24.9393L18.2451 24.3117L17.2347 26.0707L16.0176 25.368L17.9196 22.0825L16.8326 21.455L14.9383 24.7449L13.7212 24.0422L16.6336 18.9978L22.4435 22.3521L19.5311 27.3965L18.314 26.6938ZM25.1137 11.0186C25.3992 11.1846 25.6074 11.4568 25.6928 11.7757C25.7783 12.0947 25.7341 12.4346 25.5698 12.721L22.8668 17.4117C22.8128 17.5641 22.7922 17.7263 22.8065 17.8873L23.5086 20.5073L23.0666 21.2728L17.2568 17.9185L17.6987 17.153L20.3188 16.451C20.477 16.4078 20.6119 16.3043 20.6945 16.1626L23.4112 11.4748C23.5772 11.1893 23.8494 10.981 24.1684 10.8956C24.4873 10.8101 24.8272 10.8543 25.1137 11.0186Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1399_1574">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(13 32) rotate(-105)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClearIcon;
