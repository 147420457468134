import {MONTH_NAME_FORMAT} from '@app/common/constants';
import EditIcon from '@app/assets/svg/edit-icon';
import {If} from '@app/components/conditional';
import theme from '@app/themes';
import {getFormatDate} from '@app/utils/dateTimeHelper';
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import {FC, useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';

import {incentiveSelector} from '../../redux/selectors';
import { StyledTableCell } from '@app/screens/Styles/style';

interface TableListProps {
  columns: string[];
  bodyData: any;
  isEmpty?: boolean;
  pagination?: {
    pageSize: number;
    pageIndex: number;
  };
  isPaging?: boolean;
  onLoadMore?: (index: number) => void;
  isLoading?: boolean;
  maxHeight?: number;
  editAction: Function;
}

const TableListDefaultProps: TableListProps = {
  columns: [],
  bodyData: [],
  isEmpty: false,
  pagination: {
    pageSize: 15,
    pageIndex: 1,
  },
  isPaging: false,
  isLoading: true,
  maxHeight: 470,
  editAction: () => {
    // This is intentional
  },
};

const TableList: FC<TableListProps> = ({
  columns,
  children,
  bodyData,
  isEmpty,
  pagination,
  isPaging,
  onLoadMore,
  editAction,
  isLoading,
  maxHeight,
}) => {
  const pageIndex = useSelector(incentiveSelector.pageIndex, shallowEqual);
  const totalCount = useSelector(incentiveSelector.totalRecords, shallowEqual);

  const isMoreLoad = useMemo(
    () =>
      isPaging && pagination && totalCount > pageIndex * pagination.pageSize,
    [totalCount, pagination, isPaging, pageIndex],
  );

  const handleScroll = (e: any) => {
    const target = e.target;
    const scrollHeight = target.scrollHeight;
    const scrollTop = target.scrollTop;
    const clientHeight = target.clientHeight;
    const bottom = Math.floor(scrollHeight - scrollTop) === clientHeight;

    if (bottom && onLoadMore) {
      onLoadMore(pageIndex + 1);
    }
  };

  return (
    <TableContainer
      sx={{pl: 5, pr: 5, maxHeight}}
      onScroll={isMoreLoad ? handleScroll : () => null}
    >
      {isLoading && !isMoreLoad ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '60vh',
          }}
        >
          <CircularProgress
            sx={{color: 'primary.main', margin: '0 auto'}}
            disableShrink
          />
        </Box>
      ) : null}

      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => {
              const index_key = index.toString();
              return (
                <StyledTableCell
                  style={{minWidth: 70}}
                  key={index_key}
                  align={'left'}
                >
                  {column}
                </StyledTableCell>
              );
            })}
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <If condition={bodyData && bodyData.length > 0}>
            {bodyData.map((request: any, index: number) => {
              const index_key = index.toString();
              return (
                <TableRow key={index_key}>
                  {/* Removed this col because of horizontal scroll */}
                  {/* <StyledTableCell> 
                  {getFormatDate({
                    date: request?.unlockDate,
                    format: MONTH_NAME_FORMAT,
                  })}
                </StyledTableCell> */}
                  <StyledTableCell>{request?.divisionName}</StyledTableCell>
                  <StyledTableCell>{request?.stateName}</StyledTableCell>
                  <StyledTableCell>
                    {getFormatDate({
                      date: request?.unlockTillDate,
                      format: MONTH_NAME_FORMAT,
                    })}
                  </StyledTableCell>
                  <StyledTableCell>{request?.reason}</StyledTableCell>
                  <StyledTableCell>{request?.requestedFor}</StyledTableCell>
                  <If condition={request?.isActive === 0}>
                    <StyledTableCell
                      sx={{
                        color: theme.colors.orange[200],
                      }}
                    >
                      Expired
                    </StyledTableCell>
                  </If>
                  <If condition={request?.isActive !== 0}>
                    <StyledTableCell
                      sx={{
                        color: theme.colors.green[100],
                      }}
                    >
                      Active
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        sx={{p: 0}}
                        data-testid={`edit-data-${index}`}
                        onClick={() => {
                          editAction(request);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </StyledTableCell>
                  </If>
                </TableRow>
              );
            })}
          </If>

          {!isEmpty ? (
            children
          ) : (
            <StyledTableCell colSpan={5}>No Record Found</StyledTableCell>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <StyledTableCell colSpan={columns.length}>
              {isMoreLoad && isLoading ? (
                <span style={{marginLeft: '50%'}}>Loading...</span>
              ) : (
                <span style={{visibility: 'hidden'}} />
              )}
            </StyledTableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

TableList.defaultProps = TableListDefaultProps;

export default TableList;
