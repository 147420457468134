import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Link,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {FilePreviewContent} from './FilePreviewContent';
import {DailogModalStyle as styles} from './styles';
import {getAccessToken} from '@app/helper/login';
import env from '@app/env.json';
import axios from 'axios';
import { generateUUID } from '@app/utils/applicationInsight';
const dialogStyle = {
  width: 425,
  height: 500,
  backgroundColor: '#FAFAFA',
  alignSelf: 'center',
  borderRadius: 10,
  borderWidth: 0.5,
  borderColor: 'black',
  alignContent: 'center',
  marginTop: 100,
  marginBottom: 150,
  padding: 15,
};

interface FilePreviewModalProps {
  innerModalOpen: boolean;
  handleFilePreviewModalClose: () => void;
  fileName: string;
}

export const util: any = {
  getFileTypeWeb: null,
  base64Download: null,
  handleDownload: null,
};

const FilePreviewModal: React.FC<FilePreviewModalProps> = ({
  innerModalOpen,
  handleFilePreviewModalClose,
  fileName,
}) => {
  const client = axios.create();
  const [base, setBase] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  client.defaults.timeout = env.API_TIMEOUT || 60000;

  const getNetworkResponse = async (config: any) => {
    return client(config)
      .then(async function (response: any) {
        return response;
      })

      .catch(async (error: any) => {
        return error.response || error.message;
      });
  };

  const getFileTypeWeb = (file: any) => {
    if (file?.includes('pdf') || file?.includes('PDF')) {
      return 'application/pdf';
    } else if (file?.includes('png') || file?.includes('PNG')) {
      return 'image/png';
    } else if (file?.includes('jpg') || file?.includes('JPG')) {
      return 'image/jpg';
    } else if (file?.includes('jpeg') || file?.includes('JPEG')) {
      return 'image/jpg';
    } else {
      return '';
    }
  };
  const base64Download = (base64String: any, file: any) => {
    const linkSource = `data:${getFileTypeWeb(file)};base64,${base64String}`;
    const downloadLink = document.createElement('a');
    const fileName = file;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const fileDownload = (url: any, fileName: any) => {
    const accessToken = getAccessToken();

    const config = {
      baseURL: env.API_HOST,
      method: 'POST',
      url,
      data: {
        blobName: fileName,
      },
      responseType: 'application/json',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        CorrelationId: generateUUID(),
      },
    };
    return getNetworkResponse(config);
  };

  const handleDownload = (file: any) => {
    void (async () => {
      try {
        const response = await fileDownload(
          `/inventory/voucher/tablet/download?blobName=${file}`,
          file,
        );
        if (response?.data?.error) {
          throw new Error(response.error);
        }
        base64Download(response?.data, file);
      } catch (err) {}
    })();
  };

  const handlePreview = (file: any) => {
    void (async () => {
      try {
        setLoading(true);
        const response = await fileDownload(
          `/inventory/voucher/tablet/download?blobName=${file}`,
          file,
        );

        if (response?.data?.error) {
          throw new Error(response.error);
        }
        setLoading(false);
        setBase(response.data);
        setOpen(true);
      } catch (err) {}
    })();
  };

  const handleClose = () => {
    setOpen(false);
    setBase('');
  };

  util.getFileTypeWeb = getFileTypeWeb;
  util.base64Download = base64Download;
  util.handleDownload = handleDownload;

  return (
    <Dialog PaperProps={{style: dialogStyle}} open={innerModalOpen}>
      <DialogTitle sx={styles.headerItem}>
        <div style={{flexDirection: 'row'}}>
          <Box display={'flex'} justifyContent="flex-end">
            <Button
              sx={{right: -28}}
              data-testid="modal-close"
              onClick={handleFilePreviewModalClose}
            >
              <ClearOutlinedIcon />
            </Button>
          </Box>
        </div>
      </DialogTitle>
      <DialogContent>
        <Box display={'flex'} flexDirection={'row'} m={2}>
          <Link
            style={{
              cursor: 'pointer',
              overflowWrap: 'break-word',
              marginLeft: -20,
            }}
            width={'90%'}
            onClick={() => handlePreview(fileName)}
            m={2}
          >
            {fileName}
          </Link>
          <Box sx={{marginLeft: 4}}>
            <FileDownloadOutlinedIcon
              onClick={() => handleDownload(fileName)}
              sx={{marginTop: 2, color: '#322b7c', cursor: 'pointer'}}
            />
          </Box>
          {open && (
            <FilePreviewContent
              fileName={fileName}
              fileData={base}
              loading={loading}
              open={open}
              handleClose={handleClose}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FilePreviewModal;
