import {Box, Button, Card, CircularProgress, Typography} from '@mui/material';
import up_icon from '@app/assets/images/up_icon.png';
import down_icon from '@app/assets/images/down_icon.png';
import down_arrow_1 from '@app/assets/images/down_arrow_1.png';
import down_load_icon from '@app/assets/images/down_load_icon.png';
import up_load_icon from '@app/assets/images/up_load_icon.png';
import refresh_icon from '@app/assets/images/refresh_icon.png';
import clear_icon from '@app/assets/images/clear_icon.png';
import './styles.css';
import {useCallback, useEffect, useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {incentiveSelector} from '@app/screens/incentive-configuration/redux/selectors';
import If from '@app/components/If';
import {incentiveActions} from '@app/screens/incentive-configuration/redux';
import {
  fetchsalesDistributionListDataCreator,
  fetchSalesTransferDataCreator,
  incentiveNavSearchQueryCreator,
} from '@app/screens/incentive-configuration/redux/slice';
import {returnUTCtoLocal} from '@app/utils/dateTimeHelper';
import {NetworkService, ToasterService} from '@app/services';
import {API_PATH, TOAST_MESSAGES} from '@app/common/constants';
import {ToastType} from '@app/services/toaster';
import {useLocation} from 'react-router-dom';
import {UploadProgress} from '@app/components/uploadProgress';

const CustomScrollbar = styled('div')({
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#7090B0',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
});

let prevSearchQuery = '';

const IncentiveConfiguration = ({isSalesDistribution}) => {
  const dispatch = useDispatch();

  const [unchangeFlag, setUnchnageFlag] = useState(false);
  const [unchangeIndex, setUnchnageIndex] = useState(0);
  const [isDownloadAllFlag, setIsDownloadFlag] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  let salesTransferData = useSelector(
    incentiveSelector.getSalesTransferData(),
    shallowEqual,
  );
  const salesDistributionDataList = useSelector(
    incentiveSelector.getSalesDistributionList(),
    shallowEqual,
  );
  const search = useSelector(incentiveSelector.getNavSearch(), shallowEqual);

  const data = isSalesDistribution
    ? salesDistributionDataList
    : salesTransferData;

  const firstAndLastLatterOfName = name => {
    let temp = name.split(' ');
    let [fName, lName] = temp;

    let firstLattername = fName ? fName?.charAt(0) : '';
    let LastLatterOfLastName = lName ? lName?.charAt(0) : '';

    return firstLattername + LastLatterOfLastName;
  };

  useEffect(() => {
    if (data && !data?.isAuditTrailData) setUnchnageIndex(0);
    console.log('salesTransferData', data);
  }, [data]);

  const clickOnAuditTrail = useCallback(
    (data, index, isRequired, searchKey) => {
      console.log("clickOnAuditTrail called", index);
      if (unchangeIndex === index && !isRequired) return;
      setUnchnageIndex(index);
      if (isSalesDistribution) {
        console.log('data', data);
        dispatch(
          fetchsalesDistributionListDataCreator({
            auditTrailId: data.id,
            CreatedOn:
              data?.actionType === 'Added' ? data?.createdOn : data?.modifiedOn,
            from: 'AuditTrail',
          }),
        );
      } else {
        dispatch(incentiveActions.setAuditTrailActionHandlerData(data));
        let dataParam = {
          isProductView: true,
          ActionType: data.actionType,
          Id: data.id,
          CreatedOn:
            data?.actionType === 'Added' ? data?.createdOn : data?.modifiedOn,
          from: 'AuditTrail',
          searchKey,
          // CreatedBy: data?.createdBy,
        };
        dispatch(fetchSalesTransferDataCreator(dataParam));
      }
    },
    [unchangeIndex, isSalesDistribution, search],
  );

  const searchTimeout = useRef();

  const searchAuditTrailData = () => {
    if (
      data?.isAuditTrailData &&
      data?.auditTrail?.length &&
      data?.auditTrail[unchangeIndex] && (search?.query?.length > 2 ||
        (search?.query?.length === 0 && prevSearchQuery.length))
    ) {
      clickOnAuditTrail(data?.auditTrail[unchangeIndex], unchangeIndex, true, search?.query?.trim());
      prevSearchQuery = search?.query;
    }
  }

  useEffect(() => {
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      searchAuditTrailData();
    }, 500)
  }, [search]);

  const handleDownload = useCallback(() => {
    setIsDownloadFlag(!isDownloadAllFlag);
  }, [isDownloadAllFlag]);

  const download = useCallback(
    async (val, id) => {
      const lastUpdateData = data?.auditTrail[0];
      const salesTransferId = lastUpdateData?.salesTransferId;
      const traildata = data?.auditTrail[unchangeIndex];
      setIsDownloadFlag(false);
      setIsDownloading(true);
      let requestParams = {};
      let endpoint = API_PATH.incentiveConfiguration.incentiveAdutTrailExcel;
      if (isSalesDistribution) {
        endpoint =
          API_PATH.incentiveConfiguration.incentiveDistributionAdutTrailExcel;
        if (val === 'all') {
          requestParams = {
            incentiveDistributionId: lastUpdateData?.incentiveDistributionId,
          };
        } else {
          requestParams = {
            auditTrailId: traildata?.id,
          };
        }
      } else {
        if (val === 'all') {
          requestParams = {
            SalesTransferId: salesTransferId,
          };
        } else {
          const traildata = data?.auditTrail[unchangeIndex];
          requestParams = {
            Id: traildata?.id,
          };
        }
      }

      const response = await NetworkService.post(endpoint, requestParams, {});
      if (response.status !== 200) {
        ToasterService.showToaster(
          TOAST_MESSAGES.DOWNLOAD_FAILED,
          ToastType.ERROR,
        );
      } else {
        window.open(response?.data, '_blank');
      }
      setIsDownloading(false);
    },
    [unchangeIndex, data],
  );

  const handleFetchLatestData = () => {
    if (unchangeIndex !== 0) {
      if (isSalesDistribution) {
        dispatch(incentiveActions.setRefetchSalesDistribution(Math.random()));
      } else {
        dispatch(incentiveActions.setRefetchSalesTransfer(Math.random()));
      }
    }
  };

  const renderDownLoadCard = useCallback(() => {
    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          borderRadius: 2,
          marginBottom: 2,
          position: 'absolute',
          bottom: '50px',
          px: 1,
          py: 0,
        }}>
        <Button
          onClick={() => download('all')}
          sx={{
            fontSize: 11,
            fontWeight: 600,
            color: '#1C1939',
            padding: 1,
            py: '10px',
            display: 'flex',
            justifyContent: 'flex-start',
          }}>
          {'Download All'}
        </Button>
        <Box
          sx={{
            borderBottom: '1px solid rgba(112, 144, 176, 0.32)',
            width: '100%',
          }}></Box>
        <Button
          onClick={() => download('selected')}
          sx={{
            fontSize: 11,
            fontWeight: 600,
            color: '#1C1939',
            padding: 1,
            py: '10px',
            display: 'flex',
            justifyContent: 'flex-start',
          }}>
          {'Download Selected'}
        </Button>
      </Card>
    );
  }, [unchangeIndex, data]);

  return (
    <>
      <UploadProgress />
      <Box
        margin={'20px'}
        borderRadius={'20px'}
        sx={{backgroundColor: '#fff'}}
        className={`hide-scrollbar`}
        height={'90vh'}
        padding={1}>
        <Box sx={{padding: 1}}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              marginBottom: 1,
            }}>
            <img src={down_load_icon} alt="Logo" height={12} width={12} />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 11,
                color: '#322B7C',
              }}>
              Download Excel
            </Typography>
          </Box>
          {isSalesDistribution ? null : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                marginBottom: 1,
              }}>
              <img src={up_load_icon} alt="Logo" height={12} width={12} />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: 11,
                  color: '#322B7C',
                }}>
                Upload Excel
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              marginBottom: 1,
            }}>
            <img src={refresh_icon} alt="Logo" height={12} width={12} />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 11,
                color: '#322B7C',
              }}>
              Refresh
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              marginBottom: 1,
            }}>
            <img src={clear_icon} alt="Logo" height={12} width={12} />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 11,
                color: '#322B7C',
              }}>
              Clear
            </Typography>
          </Box>

          <Typography
            sx={{
              fontSize: '13px',
              color: '#322B7C',
              fontWeight: 500,
              marginTop: 2,
            }}>
            Last Updated By
          </Typography>
        </Box>
        <CustomScrollbar
          style={{
            height: unchangeFlag && data?.auditTrail?.length > 7 ? 400 : null,
            overflow: 'auto',
            overflowX: 'hidden',
          }}>
          <Box>
            {data?.auditTrail &&
              data?.auditTrail?.length > 0 &&
              data?.auditTrail?.map((val, index) => {
                return (
                  <>
                    <If condition={index === 0}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '10px 0px 10px 8px',
                        }}
                        className={`main_box_unchange ${
                          unchangeIndex === 0 ? 'active' : ''
                        }`}
                        onClick={() => {
                          setUnchnageIndex(index);
                          handleFetchLatestData();
                        }}>
                        <Box display="flex" alignItems="center" flex={1}>
                          <Box className="profile-icon">
                            <Typography
                              noWrap
                              component="div"
                              color={'#fff'}
                              fontStyle={'normal'}
                              textAlign={'center'}
                              fontSize={12}>
                              {firstAndLastLatterOfName(
                                val?.modifiedByName || val?.createdByName,
                              )}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              marginLeft: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}>
                            <Typography
                              // variant="h6"
                              noWrap
                              component="div"
                              color={'#322B7C'}
                              fontSize={14}
                              fontStyle={'normal'}
                              width={115}
                              textAlign={'left'}>
                              {val?.modifiedByName || val?.createdByName}
                            </Typography>
                            <Box sx={{display: 'flex'}}>
                              <Typography
                                variant="caption"
                                // noWrap
                                fontSize={10}
                                component="div"
                                color={'#322B7CB2'}
                                fontStyle={'normal'}
                                width={115}>
                                {returnUTCtoLocal(
                                  val?.modifiedOn || val?.createdOn,
                                  'D MMM YYYY, h:mmA',
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            ':hover': {
                              backgroundColor: '#d7d5d5',
                              borderRadius: '4px',
                            },
                          }}
                          onClick={() => {
                            setUnchnageFlag(!unchangeFlag);
                          }}
                          padding={'8px 14px 8px 14px'}>
                          <img
                            src={unchangeFlag ? up_icon : down_arrow_1}
                            alt="Logo"
                            style={{
                              height: 7,
                              width: 12,
                              // marginLeft: 5,
                              marginBottom: '2px',
                            }}
                          />
                        </Box>
                      </Box>
                    </If>

                    <If condition={index !== 0 && unchangeFlag}>
                      <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Box>
                          <Box
                            sx={{
                              borderLeft: '2px dotted gray',
                              height: '17px',
                              marginLeft: '20px',
                            }}
                          />
                          <Box
                            sx={{
                              backgroundColor:
                                unchangeIndex === index && index !== 0
                                  ? '#322B7C'
                                  : '#7090B0',
                            }}
                            className="circle"
                          />
                          <Box
                            sx={{
                              borderLeft: '2px dotted gray',
                              height: '25px',
                              marginLeft: '20px',
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            if(!isSalesDistribution) {
                              dispatch(incentiveNavSearchQueryCreator(''));
                            }
                            clickOnAuditTrail(val, index, false, '');
                          }}
                          className="main_box_unchange">
                          <Box
                            sx={{
                              marginLeft: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              backgroundColor:
                                unchangeIndex === index ? '#F4F4F4' : null,
                              padding: 1,
                              borderRadius: '4px',
                            }}>
                            <Typography
                              noWrap
                              component="div"
                              color={'#322B7C'}
                              fontSize={14}
                              fontStyle={'normal'}
                              width={145}
                              textAlign={'left'}>
                              {val?.modifiedByName || val?.createdByName}
                            </Typography>
                            <Box sx={{display: 'flex'}}>
                              <Typography
                                variant="caption"
                                noWrap
                                fontSize={10}
                                component="div"
                                color={'#322B7CB2'}
                                fontStyle={'normal'}
                                textAlign={'center'}>
                                {returnUTCtoLocal(
                                  val?.modifiedOn || val?.createdOn,
                                  'D MMM YYYY, h:mmA',
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </If>
                  </>
                );
              })}
          </Box>
        </CustomScrollbar>
        <If condition={data?.auditTrail?.length > 0}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: 2,
              flexDirection: 'column',
              position: 'relative',
            }}>
            {isDownloadAllFlag && renderDownLoadCard()}
            <Button
              variant="contained"
              onClick={handleDownload}
              disabled={isDownloading}
              sx={{borderRadius: '7px', height: 44}}>
              {isDownloading ? (
                <CircularProgress
                  size={20}
                  sx={{color: '#ffffff', margin: '0 auto'}}
                  disableShrink
                />
              ) : null}
              {!isDownloading ? (
                <>
                  DownLoad
                  <img
                    src={down_icon}
                    alt="Logo"
                    style={{
                      height: 7,
                      width: 12,
                      marginLeft: 15,
                    }}
                  />
                </>
              ) : null}
            </Button>
          </Box>
        </If>
      </Box>
    </>
  );
};

export default IncentiveConfiguration;
