const DownLoadIcon = ({color}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="6.2"
        fill="#D5E2E7"
        stroke={color}
      />
      <g clip-path="url(#clip0_1399_1562)">
        <path
          d="M22.4 18.4V13.6H17.6V18.4H13.6L20 24.8L26.4 18.4H22.4ZM12 26.4H28V28H12V26.4Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1399_1562">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownLoadIcon;
