export const placeholder = (itemA: any, itemB: any[]) => {
  let Users = itemA?.filter((item1: {value: any}) => {
    return itemB?.find(item2 => {
      return item1?.value === parseInt(item2);
    });
  });
  return Users?.map((value: any) => {
    return value.label;
  });
};

export const placeholderDisplayName = (itemA: any, itemB: never[]) => {
  let Users = itemA?.filter((item1: {id: any}) => {
    return itemB?.find(item2 => {
      return item1?.id === item2;
    });
  });
  return Users?.map((value: any) => {
    return value?.displayName;
  });
};

export const placeholderforDesignation = (itemA: any, itemB: any[]) => {
  let Users = itemA?.filter((item1: {designationId: any}) => {
    return itemB?.find(item2 => {
      return item1?.designationId === parseInt(item2);
    });
  });
  return Users?.map((value: any) => {
    return value.designationName;
  });
};

export const placeholderforHq = (itemA: any, itemB: never[]) => {
  let Users = itemA?.filter((item1: {headQuaterId: any}) => {
    return itemB?.find(item2 => {
      return item1.headQuaterId === parseInt(item2);
    });
  });
  return Users?.map((value: any) => {
    return value?.headQuaterName;
  });
};

export const placeholderforHQ = (itemA: any, itemB: any[]) => {
  let Users = itemA?.filter((item1: {hqId: any}) => {
    return itemB?.find(item2 => {
      return item1.hqId === parseInt(item2);
    });
  });
  return Users?.map((value: any) => {
    return value?.headQuaterName;
  });
};
export const placeholderforPool = (itemA: any, itemB: any[]) => {
  let Users = itemA?.filter((item1: {poolId: any}) => {
    return itemB?.find(item2 => {
      return item1.poolId === parseInt(item2);
    });
  });
  return Users?.map((value: any) => {
    return value?.poolName;
  });
};
export const placeholderEmployee = (itemA: any, itemB: any[]) => {
  let Users = itemA?.filter((item1: {employeeId: any}) => {
    return itemB?.find(item2 => {
      return item1?.employeeId === item2;
    });
  });
  return Users?.map((value: any) => {
    return value?.employeeName;
  });
};
export const muiltipleSelect = (select: any) => {
  if (select?.length > 0) {
    return select
      .map(function (val: any) {
        return val;
      })
      .join('|');
  }
};

const ITEM_HEIGHT = 75;
const ITEM_PADDING_TOP = 10;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const getBase64 = (file: any) => {
  try {
    let reader = new FileReader();
    reader?.readAsDataURL?.(file);
    reader.onload = function () {
      return reader?.result;
    };
    reader.onerror = function (error) {};
  } catch (err) {}
};

export const monthMock = () => {
  return [
    {key: 1, value: 'January'},
    {key: 2, value: 'February'},
    {key: 3, value: 'March'},
    {key: 4, value: 'April'},
    {key: 5, value: 'May'},
    {key: 6, value: 'June'},
    {key: 7, value: 'July'},
    {key: 8, value: 'August'},
    {key: 9, value: 'September'},
    {key: 10, value: 'October'},
    {key: 11, value: 'November'},
    {key: 12, value: 'December'},
  ];
};

