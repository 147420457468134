import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {RoutesName, RoutesNameComponentMap} from './paths';
import {Suspense, useEffect, useState} from 'react';
import {authSelector, authTokenActions} from '@app/screens/generic/Login/redux';
import {
  fetchNotificationsCreator,
  fetchUserInfoCreator,
} from '@app/store/reducers/appSlice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {Layout} from '@app/components/layout/mainLayout';
import {RenderChilds} from '@app/components/widgets/RenderChilds';
import {appInsights} from '@app/utils/applicationInsight';
import {fetchAppRoutesCreator} from './redux/routeHandler';
import {getAccessToken} from '@app/helper/login';
import {isAccessTokenValid} from '@app/utils/util';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import {routeSelector} from './redux';
import env from '../env.json';

export const AppRouter = () => {
  const dispatch = useDispatch();
  const isRoutesLoading = useSelector(
    routeSelector.getIsRoutesLoading(),
    shallowEqual,
  );
  const isAuthorized = useSelector(
    authSelector.getIsAuthorized(),
    shallowEqual,
  );

  const appRoutes = useSelector(routeSelector.getAppRoutes(), shallowEqual);
  const userToken = useSelector(authSelector.getAuthToken(), shallowEqual);

  const [routesAdded, setRoutesAdded] = useState<any>([]);

  useEffect(() => {
    if (env.AZURE_APPINSIGHT_CONNECTION_STRING) {
      appInsights.loadAppInsights();
      appInsights.trackPageView();
    }
  }, []);
  const token = userToken || getAccessToken();

  useEffect(() => {
    const updateTokenAndScreenOnWeb = () => {
      try {
        const decoded: any = token && jwt_decode(token);
        if (token && isAccessTokenValid()) {
          dispatch(fetchAppRoutesCreator());
          dispatch(fetchUserInfoCreator());
          dispatch(
            authTokenActions.signIn({
              userToken: token,
              logout: false,
              userState: decoded,
            }),
          );
        } else {
          dispatch(
            authTokenActions.signIn({
              userToken: null,
              logout: true,
            }),
          );
        }
      } catch (error) {
        dispatch(
          authTokenActions.signIn({
            userToken: null,
            logout: true,
          }),
        );
      }
    };
    updateTokenAndScreenOnWeb();
  }, [token]);

  useEffect(() => {
    if (appRoutes && appRoutes.length > 0) {
      const routes: any = [];
      appRoutes.map((route: any) => {
        const primaryRoute = route;
        const secondaryRoutes = route.firstLevel;
        primaryRoute &&
          routes.push(
            <Route
              path={primaryRoute?.webPath}
              element={
                <Suspense fallback={<></>}>
                  {RoutesNameComponentMap[primaryRoute?.webComponent]}
                </Suspense>
              }
            />,
          );
        secondaryRoutes &&
          secondaryRoutes.length > 0 &&
          secondaryRoutes.map((secondaryRoutesRoute: any) => {
            routes.push(
              <Route
                path={secondaryRoutesRoute?.webPath}
                element={
                  <Suspense fallback={<></>}>
                    {RoutesNameComponentMap[secondaryRoutesRoute?.webComponent]}
                  </Suspense>
                }
              />,
            );

            if (secondaryRoutesRoute?.secondLevel?.length > 0) {
              secondaryRoutesRoute?.secondLevel?.map((_route: any) => {
                routes.push(
                  <Route
                    path={secondaryRoutesRoute?.webPath + '/:option'}
                    element={
                      <Suspense fallback={<></>}>
                        {RoutesNameComponentMap[_route?.webComponent]}
                      </Suspense>
                    }
                  />,
                );
                return null;
              });
            }
            return null;
          });
        return null;
      });
      setRoutesAdded(routes);
    }
  }, [appRoutes]);

  return (
    <BrowserRouter>
      {!isRoutesLoading && (
        <>
          {userToken || (getAccessToken() && isAccessTokenValid()) ? (
            
              <RenderChilds
                condition={isAuthorized}
                child1={
                  <AuthorizedLayout
                    routesAdded={routesAdded}
                    RoutesNameComponentMap={RoutesNameComponentMap}
                    RoutesName={RoutesName}
                  />
                }
                child2={
                  <UnAuthorizedLayout
                    RoutesNameComponentMap={RoutesNameComponentMap}
                    RoutesName={RoutesName}
                  />
                }
              />
            
          ) : (
            <Routes>
              <Route
                path={RoutesName.Login.path}
                element={
                  <Suspense fallback={<></>}>
                    {RoutesNameComponentMap[RoutesName.Login.componentName]}
                  </Suspense>
                }
              />
              <Route
                path={RoutesName.Auth.path}
                element={
                  <Suspense fallback={<></>}>
                    {RoutesNameComponentMap[RoutesName.Auth.componentName]}
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense fallback={<></>}>
                    <Navigate replace to={RoutesName.Login.path} />
                  </Suspense>
                }
              />
            </Routes>
          )}
        </>
      )}
    </BrowserRouter>
  );
};

export const AuthorizedLayout = ({
  routesAdded,
  RoutesNameComponentMap,
  RoutesName,
}: {
  routesAdded: any;
  RoutesNameComponentMap: any;
  RoutesName: any;
}) => (
  <Layout>
    <Routes>
      {routesAdded && routesAdded.length > 0 ? routesAdded : <></>}
      <Route
        path={RoutesName.Home.path}
        element={
          <Suspense fallback={<></>}>
            {RoutesNameComponentMap[RoutesName.Home.componentName]}
          </Suspense>
        }
      />

      <Route
        path={RoutesName._Home.path}
        element={
          <Suspense fallback={<></>}>
            {RoutesNameComponentMap[RoutesName._Home.componentName]}
          </Suspense>
        }
      />

      <Route
        path={RoutesName.InProgress.path}
        element={
          <Suspense fallback={<></>}>
            {RoutesNameComponentMap[RoutesName.InProgress.componentName]}
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<></>}>
            <Navigate replace to={RoutesName.Home.path} />
          </Suspense>
        }
      />
    </Routes>
  </Layout>
);
export const UnAuthorizedLayout = ({
  RoutesNameComponentMap,
  RoutesName,
}: {
  RoutesNameComponentMap: any;
  RoutesName: any;
}) => (
  <Layout>
    <Routes>
      <Route
        path="/unauthorized"
        element={
          <Suspense fallback={<></>}>
            {RoutesNameComponentMap[RoutesName.Unauthorized.componentName]}
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<></>}>
            <Navigate replace to={RoutesName.Unauthorized.path} />
          </Suspense>
        }
      />
    </Routes>
  </Layout>
);
