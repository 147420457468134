const UploadIcon = ({color}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="6.2"
        fill="#D5E2E7"
        stroke={color}
      />
      <g clip-path="url(#clip0_1381_250)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.3242 22.3333H22.2482V17.375H26.3846L19.8462 11.6539L13.3077 17.5385H17.3242V22.3333Z"
          fill={color}
        />
        <path d="M27.6923 24.8684H12V26.6579H27.6923V24.8684Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_1381_250">
          <rect
            width="15.6923"
            height="17"
            fill="white"
            transform="translate(12 11)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadIcon;
