import {
  Box,
  CircularProgress,
  Grid,
} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {
  fetchPoolCreator,
  fetchSLMCreator,
  fetchYearlyQuartersCreator,
  fetchfromPoolCreator,
  fetchsalesDistributionListDataCreator,
  inactiveNavbaFilterIconCreator,
  inactiveNavbarDropdownCreator,
  inactiveNavbarDropdownDivisionCreator,
  incentiveActions,
} from 'src/screens/incentive-configuration/redux/slice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Dropdown from '@app/components/dropdown';
import {Styles} from './style';
import {incentiveSelector} from 'src/screens/incentive-configuration/redux/selectors';
import {appSelector} from '@app/store/selectors';
import MultiSelectDropDown from '@app/components/multiSelectDropDown';
import If from '@app/components/If';
import {downloadExcelFile} from '@app/services/download';
import {API_PATH, TOAST_MESSAGES} from '@app/common/constants';
import {ConfirmationDialog} from '@app/components/widgets/ConfirmationDialog';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import { CustomButton } from '@app/screens/Styles/style';
import moment from 'moment';
import { isValidDate } from '@app/utils/util';

enum FilterType {
  division = 'division',
  state = 'state',
  quarter = 'quarter',
  pool = 'pool',
  group = 'group',
  slm = 'slm',
}

let quaterId: number[] = [];

function FilterHeader(props: any) {
  const dispatch = useDispatch();
  const [division, setDivision] = useState<
    {label: string; value: number} | undefined
  >(undefined);
  const [quarter, setQuarter] = useState<
    {label: string; id: number} | undefined
  >(undefined);
  const [group, setGroup] = useState<
    {label: string; value: number, flag: string} | undefined
  >(undefined);
  const [pool, setPool] = useState<any>({});
  const [slm, setSLM] = useState<{label: string; value: number} | undefined>(
    undefined,
  );

  const [selectedFilter, setSelectedFilter] = useState({
    stateIdList: null,
    divisionId: null,
    quarter: null,
    configuration: null,
  });
  const [showDownloadConfirmation, setShowDownloadConfirmation] =
    useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [resetSlmDropdown, setResetSlmDropdown] = useState<false | 1>(false);
  const [resetGrpPoolDropdown, setResetGrpPoolDropdown] = useState<false | 1>(
    false,
  );
  const [currentQuarterEditableDate, setCurrentQuarterEditableDate] = useState("");

  const divisionList = useSelector(
    incentiveSelector.getDivisionList(),
    shallowEqual,
  );

  const quarterList = useSelector(
    incentiveSelector.getQuartetList(),
    shallowEqual,
  );

  const refetchSalesDistribution = useSelector(
    incentiveSelector.getRefetchSalesDistribution(),
    shallowEqual,
  );
  const salesDistributionDataList = useSelector(
    incentiveSelector.getSalesDistributionList(),
    shallowEqual,
  );
  const clearSalesDistributionData = useSelector(
    incentiveSelector.getClearSalesDistribution(),
    shallowEqual,
  );

  const [isDisbleGoBtn, setIsDisbleGoBtn] = useState<any>(true);
  const [quaterListArr, setQuaterListArr] = useState<any>([]);
  const [divisionListId, setDivisionListId] = useState<any>([]);
  const [quaterListId, setQuaterListId] = useState<any>([]);
  const [selectedDivisition, setSelectedDivisition] = useState<any>([]);
  const [staffPositionIdList, setStaffPositionIdList] = useState<any>([]);
  const [selectedQuater, setSelectedQuater] = useState<any>([]);
  const [selectedSlm, setSelectedSlm] = useState<any>([]);
  const [selectedGrpPool, setSelectedGrpPool] = useState<any>([]);
  const [grpPoolList, setGrpPoolList] = useState<any>([]);

  const isFilterIconStatus = useSelector(incentiveSelector.getNavFilterIcon());
  const slmList = useSelector(incentiveSelector.getSLMData());
  const groupPoolList = useSelector(incentiveSelector.getGrpOfPoolData());

  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const adminStaffPositionMasterId = userInfo.id;

  const clearFilterData = useSelector(incentiveSelector.clearFilterData());
  const isActive = useSelector(incentiveSelector.getStatus, shallowEqual);

  useEffect(() => {
    const currentQuarter = quarterList?.find((el: any) => el.isCurrentQuarter)
    if(currentQuarter && !currentQuarterEditableDate)
      fetchQuarterDownloadDate(currentQuarter?.id);
  }, [quarterList]);

  useEffect(() => {
    if(divisionList?.length && !divisionListId?.length && isActive === 1) {
      const allDivisionids = divisionList.map((d: any) => d.value);
      dispatch(fetchSLMCreator(allDivisionids));
    }
  }, [divisionList, isActive]);

  useEffect(() => {
    if(slmList?.length && !staffPositionIdList?.length && isActive === 1) {
      fetchDownloadGroupOfPools([]);
    }
  }, [slmList, isActive]);

  useEffect(() => {
    dispatch(
      inactiveNavbaFilterIconCreator({
        value: false,
        color: 'gray',
        gobtnClick: false,
      }),
    );
    dispatch(fetchYearlyQuartersCreator());
    dispatch(inactiveNavbarDropdownCreator({}));
    dispatch(
      inactiveNavbarDropdownDivisionCreator({
        adminStaffPositionMasterId: adminStaffPositionMasterId,
      }),
    );
  }, []);

  useEffect(() => {
    if (clearSalesDistributionData) {
      dataClear();
      dispatch(incentiveActions.setClearSalesDistributionData(false));
    }
  }, [clearSalesDistributionData]);

  useEffect(() => {
    if (clearFilterData) {
      resetData();
    }
  }, [clearFilterData]);

  const resetData = () => {
    setDivision(undefined);
    setQuarter(undefined);
    setPool(undefined);
    setGroup(undefined);
    setSLM(undefined);
    dispatch(incentiveActions.toggleClearFilter(false));
  };

  useEffect(() => {
    if (
      refetchSalesDistribution &&
      isActive === 0 &&
      salesDistributionDataList?.isAuditTrailData
    ) {
      filterHandler();
    }
  }, [refetchSalesDistribution]);

  const dataClear = async () => {
    const dataParam = {
      staffPositionId: slm?.value,
      quarterId: quarter?.id,
      grouppoolid: group?.value ?? 0,
    };
    setIsClearing(true);
    const response = await NetworkService.post(
      API_PATH.incentiveConfiguration.deleteSalesDistributionData,
      {},
      dataParam,
    );

    if (response.status !== 200) {
      ToasterService.showToaster(
        response.data?.description || TOAST_MESSAGES.UNSUCCESSFULL_UPDATE,
        ToastType.ERROR,
      );
    } else {
      ToasterService.showToaster(
        TOAST_MESSAGES.SUCCESSFULLY_UPDATED,
        ToastType.SUCCESS,
      );
      filterHandler();
    }
    setIsClearing(false);
  };

  const selectfilterHandler = useCallback(
    (val: any, typeOfFilter: FilterType) => {
      let value: any;
      switch (typeOfFilter) {
        case FilterType.division:
          value = {
            ...selectedFilter,
            divisionId: val?.value,
          };
          break;
        case FilterType.state:
          value = {
            ...selectedFilter,
            stateIdList: val?.value,
          };
          break;
        case FilterType.quarter:
          value = {
            ...selectedFilter,
            quarter: val?.label,
          };
          break;
        case FilterType.slm:
          value = {
            ...selectedFilter,
            slm: val?.label,
          };
          break;
      }
      setSelectedFilter(value);
    },
    [selectedFilter],
  );

  const filterHandler = useCallback(
    (e?: any) => {
      e?.preventDefault();
      if (isActive === 1) {
        handleDownload();
        return;
      }
      dispatch(
        inactiveNavbaFilterIconCreator({
          value: !isFilterIconStatus?.value,
          gobtnClick: true,
          color: 'blue',
        }),
      );
      const requestParams: any = {
        staffPositionId: slm?.value,
        divisionId: division?.value,
        quarterId: quarter?.id,
        groupPoolId: group?.value,
        poolId: pool?.value,
        flag: group?.flag
      };

      props.filterData(requestParams, quarter);
      dispatch(fetchsalesDistributionListDataCreator(requestParams));
    },
    [
      division,
      slm,
      quarter,
      pool,
      dispatch,
      group,
      isActive,
      divisionListId,
      staffPositionIdList,
      grpPoolList,
      quaterListId,
    ],
  );

  const handleDownload = () => {
    if (quaterListId.length || divisionListId.length || staffPositionIdList.length) {
      onDownloadHandler();
    } else {
      setShowDownloadConfirmation(true);
    }
  };

  const onDownloadHandler = async () => {
    setShowDownloadConfirmation(false);
    const params = {
      staffPositionIds: staffPositionIdList,
      quarterIds: quaterListId,
      groupPoolIds: grpPoolList,
      divisionIds: divisionListId,
    };
    setIsDownloading(true);
    await downloadExcelFile(
      API_PATH.incentiveConfiguration.fetchDownloadExcelSalesDistributionURL,
      params,
    );
    setIsDownloading(false);
  };

  useEffect(() => {
    if (pool || group) {
      setIsDisbleGoBtn(false);
    } else {
      setIsDisbleGoBtn(true);
    }

    if (!division) {
      setIsDisbleGoBtn(true);
    }
  }, [pool, group]);

  useEffect(() => {
    setSelectedDivisition([]);
    setSelectedSlm([]);
    setDivisionListId([]);
    setDivision(undefined);
    setSLM(undefined);
    setStaffPositionIdList([]);
    setGroup(undefined);
    dispatch(incentiveActions.setSLMData([]))
    dispatch(incentiveActions.setFromPoolData([]));
    dispatch(incentiveActions.setSalesDistributionBackupListData(null));
    dispatch(incentiveActions.setSalesDistributionListData(null));
  }, [isActive]);

  useEffect(() => {
    if (quarterList.length > 0) {
      let ans: any = [];
      quarterList.forEach((ele: any) => {
        ans.push({
          value: ele.id,
          label: ele.name,
        });
      });
      setQuaterListArr(ans);
      byDefaultSelectedQuater(quarterList);
    }
  }, [isActive, quarterList]);

  const byDefaultSelectedQuater = (quarterList: any) => {
    try {
      if (quarterList.length > 0) {
        let temp: any = [];
        quarterList.forEach((ele: any) => {
          if (ele?.isCurrentQuarter) {
            temp.push(ele.name);
            setQuarter({
              id: ele?.id,
              label: ele?.name,
            });
          }
        });
        setSelectedQuater(temp);
        const quaterId = getIdListArr(temp, quaterListArr,'');
        setQuaterListId(quaterId);
      }
    } catch (error) {
      console.log('-------- error -----------', error);
    }
  };

  const handleQuater = useCallback(
    (val: any) => {
      setSelectedQuater(val);
      let ids = [];
      if (quaterListArr?.length > 0 && val?.length > 0) {
        ids = getIdListArr(val, quaterListArr, '');
      }
      setQuaterListId(ids);
      if(JSON.stringify(quaterListId) !== JSON.stringify(quaterId)) {
        fetchDownloadGroupOfPools(staffPositionIdList, ids)
        setResetSlmDropdown(1);
        setTimeout(() => {
          setResetSlmDropdown(false);
        }, 500);
      }
    },
    [quaterListArr, quaterListId, staffPositionIdList, selectedQuater],
  );

  const getIdListArr = (listName: string[], data: any, from: string) => {
    const ans = data.reduce((acc: any, obj: any) => {
      if (listName.length > 0) {
        listName.forEach((val: any) => {
          if (val === obj.label) {
            if(from === 'grpOfPools'){
              acc.push({value: obj.value, flag: obj.flag});
            } else{
              acc.push(obj.value);
            }
          }
        });
      }
      return acc;
    }, []);
    return ans;
  };

  const handleMultiSelectionDivision: any = useCallback(
    (e: string[]) => {
      setSelectedDivisition(e);
      if (divisionList?.length > 0 && e.length > 0) {
        let divisionIdListArr = getIdListArr(e, divisionList, '');
        setDivisionListId(divisionIdListArr);
        dispatch(fetchSLMCreator(divisionIdListArr));
      } else {
        const allDivisionids = divisionList.map((d: any) => d.value);
        dispatch(fetchSLMCreator(allDivisionids));
        setDivisionListId([]);
      }
      setResetSlmDropdown(1);
      setTimeout(() => {
        setResetSlmDropdown(false);
      }, 500);
    },
    [dispatch, divisionList, divisionListId],
  );

  const handleSlm = useCallback(
    (val: any, isReset?: boolean) => {
      setSelectedSlm(val);
      let slmId = getIdListArr(val, slmList, '');
      setStaffPositionIdList(slmId);
      if(!isReset) {
        fetchDownloadGroupOfPools(slmId);
      }
      setResetGrpPoolDropdown(1);
      setTimeout(() => {
        setResetGrpPoolDropdown(false);
      }, 500);
    },
    [slmList, selectedSlm, quaterListId.length, staffPositionIdList],
  );
 
  const handleGrpPool = useCallback(
    (val: any) => {
      setSelectedGrpPool(val);
      let slmId = getIdListArr(val, groupPoolList, 'grpOfPools');
      setGrpPoolList(slmId);
    },
    [groupPoolList, selectedGrpPool, groupPoolList],
  );

  const fetchDownloadGroupOfPools = (selectedSlmIds: any, selectedQuarterIds?: any) => {
    const allSlmIds = slmList.map((s: any) => s.value);
    const slmIds = selectedSlmIds.length ? selectedSlmIds : allSlmIds;
    if(!slmIds.length) return;
    dispatch(
      fetchfromPoolCreator({
        staffPositionIdList: slmIds,
        quarterList: selectedQuarterIds || quaterListId,
      }),
    );
  }

  const getDownloadButtonDisabled = () => {
    const currentQuarter = quarterList?.find((el: any) => el.isCurrentQuarter)
    if(currentQuarterEditableDate && selectedQuater.length === 1 && currentQuarter?.name == selectedQuater[0]) {
      return moment(currentQuarterEditableDate).isAfter(moment());
    } else {
      return false;
    }
  }

  const fetchQuarterDownloadDate = async (quarterId: number) => {
    const response = await NetworkService.get(API_PATH.incentiveConfiguration.getQuarterEditableStartDate, {quarterId}, {});
    if(typeof response?.data === 'string' && isValidDate(response.data)) {
      setCurrentQuarterEditableDate(response.data);
    }
  }

  return (
    <>
      <div style={{}}>
        <div style={Styles.filterHeaderBody}>
          <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 1}}>
            <Grid item xs={2.2}>
              <Box style={Styles.inputCell}>
                <Box style={Styles.text}>DIVISION</Box>
                <If condition={isActive === 0}>
                  <Dropdown
                    onChange={(val: any) => {
                      selectfilterHandler(val, FilterType.division);
                      setDivision(val || undefined);
                      if(val?.value) {
                        dispatch(fetchSLMCreator([val?.value]));
                      }
                      setSLM(undefined);
                      setGroup(undefined);
                      setPool(undefined);
                      byDefaultSelectedQuater(quarterList);
                    }}
                    value={division}
                    dropdownList={divisionList || []}
                    placeholder="Select Division"
                  />
                </If>
                <If condition={isActive === 1}>
                  <MultiSelectDropDown
                    dropdownWidth={135}
                    width={'100%'}
                    isSearchable
                    options={divisionList || []}
                    placeholder="Select Division"
                    selectedValues={(e: string[]) =>
                      handleMultiSelectionDivision(e)
                    }
                    defaultSelected={selectedDivisition || []}
                  />
                </If>
              </Box>
            </Grid>
            <Grid item xs={2.2}>
              <Box style={Styles.inputCell}>
                <Box style={Styles.text}>QUARTER</Box>
                <If condition={isActive === 0}>
                  <Dropdown
                    onChange={(val: any) => {
                      selectfilterHandler(val, FilterType.quarter);
                      setQuarter(val);
                      setGroup(undefined);
                      setPool(undefined);
                      setSLM(undefined);
                    }}
                    value={quarter}
                    dropdownList={quarterList}
                    placeholder="Select Quarter"
                  />
                </If>
                <If condition={isActive === 1}>
                  <MultiSelectDropDown
                    dropdownWidth={135}
                    width={'100%'}
                    isSearchable
                    options={quaterListArr || []}
                    placeholder="Quarter"
                    selectedValues={(e: string[]) => handleQuater(e)}
                    defaultSelected={selectedQuater}
                  />
                </If>
              </Box>
            </Grid>
            <Grid item xs={2.2}>
              <Box style={Styles.inputCell}>
                <Box style={Styles.text}>SLM</Box>
                <If condition={isActive === 0}>
                  <Dropdown
                    onChange={(val: any) => {
                      selectfilterHandler(val, FilterType.slm);
                      setSLM(val || undefined);
                      setGroup(undefined);
                      setPool(undefined);
                      dispatch(incentiveActions.setFromPoolData([]));
                      dispatch(incentiveActions.setPoolData([]));
                      if (val) {
                        dispatch(
                          fetchPoolCreator({
                            StaffPositionId: val?.value,
                            divisionId: division?.value,
                            QuarterId: quarter?.id,
                            grouppoolid: 0,
                          }),
                        );
                        dispatch(
                          fetchfromPoolCreator({
                            staffPositionIdList: [val?.value],
                            quarterList: [quarter?.id],
                          }),
                        );
                      }
                    }}
                    value={slm}
                    dropdownList={slmList || []}
                    placeholder="Select SLM"
                    disabled={!division || !quarter}
                  />
                </If>
                <If condition={isActive === 1}>
                  <MultiSelectDropDown
                    dropdownWidth={130}
                    width={'100%'}
                    isSearchable
                    options={slmList || []}
                    placeholder="Select SLM"
                    selectedValues={(e: string[], isReset: boolean) => handleSlm(e, isReset)}
                    defaultSelected={selectedSlm}
                    reset={resetSlmDropdown}
                  />
                </If>
              </Box>
            </Grid>
            <Grid item xs={2.2}>
              <Box style={Styles.inputCell}>
                <Box style={Styles.text}>GRP OF POOL</Box>
                <If condition={isActive === 0}>
                  <Dropdown
                    onChange={(val: any) => {
                      selectfilterHandler(val, FilterType.group);
                      setGroup(val || undefined);
                      if (val) {
                        dispatch(
                          fetchPoolCreator({
                            StaffPositionId: slm?.value,
                            divisionId: division?.value,
                            QuarterId: quarter?.id,
                            grouppoolid: val?.value,
                            from: 'fromGrpOfPool',
                          }),
                        );
                      } else {
                        dispatch(incentiveActions.setFilterPoolData([]));
                      }
                    }}
                    value={group}
                    dropdownList={groupPoolList || []}
                    placeholder="Select Group"
                    disabled={!quarter || !slm || !division || pool}
                  />
                </If>
                <If condition={isActive === 1}>
                  <MultiSelectDropDown
                    dropdownWidth={130}
                    width={'100%'}
                    isSearchable
                    options={groupPoolList || []}
                    placeholder="Select Group"
                    selectedValues={(e: string[]) => handleGrpPool(e)}
                    defaultSelected={selectedSlm}
                    reset={resetGrpPoolDropdown}
                  />
                </If>
              </Box>
            </Grid>
            <Grid
              item
              sx={{display: 'flex', alignItems: 'center', marginTop: 3.5}}>
              <CustomButton
                data-testid="clickGo-icon"
                variant="contained"
                onClick={filterHandler}
                size="small"
                disabled={isActive === 0 ? isDisbleGoBtn : getDownloadButtonDisabled()}
                style={{
                  fontFamily: ['Poppins'].join(','),
                  fontSize: 12.7,
                  cursor: 'pointer',
                  letterSpacing: 0,
                  textAlign: 'center',
                  backgroundColor:
                    isDisbleGoBtn && isActive === 0 ? '#e0e0e0' : getDownloadButtonDisabled() && isActive === 1 ? '#e0e0e0' : '#322b7c',
                  color: 'white',
                  border: 0,
                  borderRadius: 10,
                  minWidth: 50,
                }}>
                {isActive === 0 ? 'Go' : 'Download'}
              </CustomButton>
            </Grid>
          </Grid>
        </div>
      </div>
      {isDownloading || isClearing ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '1vh',
          }}>
          <CircularProgress
            sx={{color: 'primary.main', margin: '0 auto'}}
            disableShrink
          />
        </Box>
      ) : null}
      <ConfirmationDialog
        open={showDownloadConfirmation}
        onClose={(status: boolean) => {
          setShowDownloadConfirmation(false);
          if (status) onDownloadHandler();
        }}
        message="You have requested to download the complete data for current financial year. Do you want to Continue?"
      />
    </>
  );
}

export default React.memo(FilterHeader);
