import * as React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

interface DatePickerProps {
  readonly value: Date | null;
  readonly onChange: Function;
  readonly placeholder: string;
  readonly disablePast: boolean;
  readonly onError: Function;
  readonly inputStyle?: any;
}

function DatePickerComponent(props: DatePickerProps) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const date = new Date();  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        open={open}
        onError={() => props.onError()}
        value={props.value}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disablePast={props.disablePast}
        label={props.placeholder}
        inputFormat="dd-MM-yyyy"
        maxDate={date.setFullYear(date.getFullYear() + 10)}
        onChange={(newValue: any) => {
          props.onChange(newValue);
        }}
        PopperProps={{
          placement: "top",
          anchorEl: anchorEl,
          style: { maxHeight: '200px' } 
        }}
        renderInput={params => (
          <TextField
            {...params}
            onClick={e => { 
              setOpen(true); 
              setAnchorEl(e.currentTarget); 
            }}
            variant="outlined"
            style={{ width: '100%', borderRadius: 10, backgroundColor: 'white' }}
            sx={{
              svg: {
                color: '#322b7c',
              },
              label: {
                '::placeholder': '#3a1c1939',
                fontFamily: ['Poppins'].join(','),
                fontSize: 13,
                fontWeight: 'normal',
                marginTop: -0.5,
                paddingLeft: 0.5,
                opacity: 0.6,
              },
              input: props.inputStyle,
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default React.memo(DatePickerComponent);
