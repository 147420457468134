import {TableRow, Typography} from '@mui/material';

import DialogBox from '@app/components/dialogBox';
import React, {useCallback, useEffect, useState} from 'react';
import {incentiveSelector} from '@app/screens/incentive-configuration/redux/selectors';
import {useDispatch, useSelector} from 'react-redux';
import {fetchSalesTransferGoBtnCreator} from '@app/screens/incentive-configuration/redux/slice';
import { StyledTableCell } from '@app/screens/Styles/style';

function ExpandedCell(props: any) {
  const {row} = props;
  const labelRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);
  const [header, setHeader] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [dataList, setDataList] = useState<any>({});
  const salesTransferDataState:  any = useSelector(
    incentiveSelector.getSalesTransferData(),
  );

  const isEditable = !salesTransferDataState?.isLocked && salesTransferDataState?.isEditor;

  const dispatch = useDispatch();

  const openModal = React.useCallback(
    (e: any, info: string) => {
      const clientWidth = labelRef?.current?.clientWidth ?? 0;
      const scrollWidth = labelRef?.current?.scrollWidth ?? 0;
      if (!isEditable && clientWidth < scrollWidth) {
        setHeader('Remarks');
        setMessage(info);
        setOpen(true);
      }
    },
    [isEditable, labelRef],
  );

  const closeModal = React.useCallback(() => {
    setOpen(false);
    setMessage('');
    setHeader('');
    props.onUnMount(!props.unmount);
  }, [props.unmount]);

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      openModal(e, row.remarks);
    }
  };

  useEffect(() => {
    setDataList(row);
  }, [row]);

  const onChangeMonth = useCallback(
    (val: any, from: string) => {
      try {
        dispatch(
          fetchSalesTransferGoBtnCreator({
            isEdited: true,
            isRefreshEditable: true,
          }),
        );

        let temp = {...dataList};
        let rejex = /^\d+(?:\.\d)?$/;
        if (rejex.test(val.target.value) || !val.target.value) {
          if (from === 'month1') {
            temp.month1 = Number(val.target.value);
            setDataList(temp);
          } else if (from === 'month2') {
            temp.month2 = Number(val.target.value);
            setDataList(temp);
          } else {
            temp.month3 = Number(val.target.value);
            setDataList(temp);
          }
          let totalSumOfMonth =
            Number(temp?.month1) + Number(temp?.month2) + Number(temp?.month3);
          let incentivePercentage = temp.incentivePercent / 100;
          let incentiveAmt = incentivePercentage * totalSumOfMonth;
          temp.incentiveAmount = incentiveAmt;
          props.onUpdateHandler(temp);
        }
      } catch (error) {}
    },
    [dataList],
  );

  const onChangeRemarks = useCallback(
    (val: any) => {
      dispatch(
        fetchSalesTransferGoBtnCreator({
          isEdited: true,
          isRefreshEditable: true,
        }),
      );
      let temp = {...dataList};
      temp.remarks = val.target.value;
      setDataList(temp);
      props.onUpdateHandler(temp);
    },
    [dataList],
  );

  return (
    <>
      <DialogBox
        open={open}
        handleClose={closeModal}
        body={message}
        header={header}
      />
      <TableRow key={row.id} style={{backgroundColor: '#fff', marginBottom: 4}}>
        <StyledTableCell padding="checkbox" />
        <StyledTableCell style={{fontWeight: 500, paddingLeft: 12, paddingRight: 12}}>
          {salesTransferDataState.isProductView ? row?.productName : row?.name}
        </StyledTableCell>
        {salesTransferDataState.isProductView ? (
          <>
            <StyledTableCell>
              <div
                style={{
                  // backgroundColor: '#F2F6F7',
                  // borderRadius: 5,
                  height: 26,
                  minWidth: 70,
                  textAlign: 'left',
                  padding: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  fontWeight: 500,
                }}
              >
                {row?.pack}
              </div>
            </StyledTableCell>

            <StyledTableCell
              style={{fontWeight: 700, paddingLeft: 12, paddingRight: 12}}
            >
              <div
                style={{
                  // backgroundColor: '#F2F6F7',
                  // borderRadius: 5,
                  height: 26,
                  minWidth: 70,
                  textAlign: 'left',
                  padding: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  fontWeight: 500,
                }}
              >
                {row?.productCode}
              </div>
            </StyledTableCell>
          </>
        ) : (
          <StyledTableCell style={{fontWeight: 500}}>
            {Number(row.incentivePercent).toFixed(2)}
          </StyledTableCell>
        )}

        <StyledTableCell>
          {isEditable ? (
            <input
              style={{
                backgroundColor: '#F2F6F7',
                borderWidth: 0,
                color: '#322b7c',
                borderRadius: 5,
                height: 26,
                minWidth: 90,
                maxWidth: 90,
                textAlign: 'right',
                padding: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontWeight: 500,
              }}
              value={dataList?.month1}
              onChange={val => onChangeMonth(val, 'month1')}
              maxLength={8}
            />
          ) : (
            <div
              style={{
                backgroundColor: '#F2F6F7',
                borderRadius: 5,
                height: 26,
                minWidth: 70,
                textAlign: 'right',
                padding: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontWeight: 500,
              }}
            >
              {Number(row?.month1)}
            </div>
          )}
        </StyledTableCell>
        <StyledTableCell>
          {isEditable ? (
            <input
              style={{
                backgroundColor: '#F2F6F7',
                borderWidth: 0,
                color: '#322b7c',
                borderRadius: 5,
                height: 26,
                minWidth: 90,
                maxWidth: 90,
                textAlign: 'right',
                padding: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontWeight: 500,
              }}
              value={dataList?.month2}
              onChange={val => onChangeMonth(val, 'month2')}
              maxLength={8}
            />
          ) : (
            <div
              style={{
                backgroundColor: '#F2F6F7',
                borderRadius: 5,
                height: 26,
                minWidth: 70,
                textAlign: 'right',
                padding: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontWeight: 500,
              }}
            >
              {Number(row?.month2)}
            </div>
          )}
        </StyledTableCell>
        <StyledTableCell>
          {isEditable ? (
            <input
              style={{
                backgroundColor: '#F2F6F7',
                borderWidth: 0,
                color: '#322b7c',
                borderRadius: 5,
                height: 26,
                minWidth: 90,
                maxWidth: 90,
                textAlign: 'right',
                padding: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontWeight: 500,
              }}
              value={dataList?.month3}
              onChange={val => onChangeMonth(val, 'month3')}
              maxLength={8}
            />
          ) : (
            <div
              style={{
                backgroundColor: '#F2F6F7',
                borderRadius: 5,
                height: 26,
                minWidth: 70,
                textAlign: 'right',
                padding: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontWeight: 500,
              }}
            >
              {Number(row?.month3)}
            </div>
          )}
        </StyledTableCell>
        <StyledTableCell>
          <div
            style={{
              backgroundColor: '#F2F6F7',
              borderRadius: 5,
              height: 26,
              minWidth: 70,
              textAlign: 'right',
              padding: 5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              fontWeight: 500,
            }}
          >
            {(
              Number(dataList?.month1) +
              Number(dataList.month2) +
              Number(dataList.month3)
            ).toFixed(0)}
          </div>
        </StyledTableCell>
        {salesTransferDataState.isProductView ? null : (
          <StyledTableCell>
            <div
              style={{
                backgroundColor: '#F2F6F7',
                borderRadius: 5,
                height: 26,
                minWidth: 70,
                textAlign: 'right',
                padding: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontWeight: 500,
              }}
            >
              {Number(dataList?.incentiveAmount).toFixed(2)}
            </div>
          </StyledTableCell>
        )}

        <StyledTableCell>
          {isEditable ? (
            <input
              style={{
                backgroundColor: '#F2F6F7',
                borderWidth: 0,
                color: '#322b7c',
                borderRadius: 5,
                height: 26,
                width: 100,
                textAlign: 'left',
                padding: 5,
                flexDirection: 'column',
                justifyContent: 'center',
                fontWeight: 500,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                cursor: row?.remarks?.length ? 'pointer' : 'auto',
              }}
              value={dataList?.remarks}
              onChange={val => onChangeRemarks(val)}
              maxLength={150}
            />
          ) : (
            <Typography
              ref={labelRef}
              style={{
                backgroundColor: '#F2F6F7',
                borderRadius: 5,
                height: 26,
                width: 200,
                textAlign: 'left',
                padding: 5,
                flexDirection: 'column',
                justifyContent: 'center',
                fontWeight: 500,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                cursor: row?.remarks?.length ? 'pointer' : 'auto',
              }}
              onKeyDown={handleKeyPress}
              onClick={e => openModal(e, row?.remarks)}
            >
              {row?.remarks?.trim()}
            </Typography>
          )}
        </StyledTableCell>
      </TableRow>
    </>
  );
}

export default ExpandedCell;
