const RefershIcon = ({color}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="6.2"
        fill="#D5E2E7"
        stroke={color}
      />
      <path
        d="M14.2079 18.3412H17.0786C17.2368 18.3412 17.3884 18.2784 17.5002 18.1666C17.612 18.0547 17.6748 17.9031 17.6748 17.745C17.6748 17.5869 17.612 17.4352 17.5002 17.3234C17.3884 17.2116 17.2368 17.1488 17.0786 17.1488H15.2268C15.8251 16.3155 16.6179 15.641 17.5362 15.1838C18.4545 14.7266 19.4706 14.5006 20.4961 14.5255C21.6796 14.5255 22.8374 14.8708 23.8277 15.5189C24.818 16.167 25.5977 17.0899 26.0715 18.1744C26.1353 18.3187 26.2536 18.4317 26.4006 18.4889C26.5476 18.5461 26.7113 18.5427 26.8558 18.4795C27.0003 18.4163 27.1138 18.2984 27.1716 18.1517C27.2294 18.005 27.2268 17.8413 27.1642 17.6965C26.5975 16.3995 25.6649 15.2959 24.4805 14.5209C23.2962 13.7459 21.9115 13.3331 20.4961 13.3331C19.1497 13.2983 17.8196 13.6327 16.6497 14.2998C15.4798 14.967 14.5148 15.9416 13.8592 17.118V13.9293C13.8592 13.7712 13.7963 13.6195 13.6845 13.5077C13.5727 13.3959 13.4211 13.3331 13.2629 13.3331C13.1048 13.3331 12.9532 13.3959 12.8414 13.5077C12.7296 13.6195 12.6667 13.7712 12.6667 13.9293V17.745C12.6669 17.9031 12.7297 18.0547 12.8415 18.1664C12.9533 18.2782 13.1049 18.3411 13.2629 18.3412H14.2079Z"
        fill={color}
        stroke={color}
        stroke-width="0.2"
      />
      <path
        d="M26.6178 21.66H22.8022C22.644 21.66 22.4924 21.7229 22.3806 21.8347C22.2688 21.9465 22.206 22.0981 22.206 22.2562C22.206 22.4144 22.2688 22.566 22.3806 22.6778C22.4924 22.7896 22.644 22.8524 22.8022 22.8524H24.686C24.2085 23.6801 23.5142 24.3618 22.678 24.824C21.8417 25.2863 20.8952 25.5116 19.9404 25.4757C18.7569 25.4757 17.5991 25.1305 16.6088 24.4823C15.6185 23.8342 14.8387 22.9114 14.365 21.8268C14.3014 21.6821 14.1831 21.5686 14.0359 21.5111C13.8887 21.4537 13.7248 21.4569 13.58 21.5202C13.4353 21.5836 13.3216 21.7017 13.2639 21.8488C13.2061 21.9959 13.2092 22.1599 13.2723 22.3047C13.8389 23.6017 14.7716 24.7053 15.9559 25.4803C17.1403 26.2553 18.525 26.6681 19.9404 26.6681C21.2123 26.7033 22.4666 26.3643 23.5477 25.6931C24.6287 25.0219 25.4889 24.0482 26.0216 22.8926V26.0719C26.0216 26.2301 26.0845 26.3817 26.1963 26.4935C26.3081 26.6053 26.4597 26.6681 26.6178 26.6681C26.776 26.6681 26.9276 26.6053 27.0394 26.4935C27.1512 26.3817 27.214 26.2301 27.214 26.0719V22.2562C27.214 22.0981 27.1512 21.9465 27.0394 21.8347C26.9276 21.7229 26.776 21.6601 26.6178 21.66Z"
        fill={color}
        stroke={color}
        stroke-width="0.2"
      />
    </svg>
  );
};

export default RefershIcon;
