import React from 'react';
import {
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Button,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const FilePreviewContent: React.FC<{
  fileName: string | undefined;
  fileData: string | undefined;
  loading: boolean;
  open: boolean;
  handleClose: any;
}> = ({fileName, fileData, loading, open, handleClose}) => {
  const fileExtension: any = fileName?.split('.').pop()?.toLowerCase();

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      sx={{
        height: '500',
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Box display={'flex'} justifyContent="space-between">
          <Box>
            <Typography> Review</Typography>
          </Box>
          <Button onClick={handleClose}>
            <ClearOutlinedIcon />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress />
          </Box>
        ) : (
          <div
            style={{
              height: 'auto',
            }}
          >
            {fileExtension === 'pdf' && (
              <embed
                style={{
                  width: '100%',
                }}
                height="500"
                src={`data:application/pdf;base64,${fileData}`}
              />
            )}
            {fileExtension !== 'pdf' && fileExtension !== '' && (
              <img
                style={{width: '100%'}}
                src={`data:image;base64,${fileData}`}
                alt={fileName}
              />
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
