// MultiSelectDropDown.js
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {Box} from '@mui/system';
import {Styles} from './style';
import { SearchTextField, StyledCheckbox, TitleTypography } from '@app/screens/Styles/style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
let temp = [];
const MultiSelectDropDown = props => {
  const {
    defaultSelected,
    selectedValues,
    options,
    isSelected,
    isSearchable,
    width,
    dropdownWidth,
    placeHolder,
    disabled,
    reset
  } = props;

  const [selectedValue, setSelectedValue] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchtxt, setSearcTxt] = useState('');
  const [dataListArr, setDataListArr] = useState([]);
  const [select, SetSelect] = useState(false);
  const [aleradySelected, setAlreadySelected] = useState(defaultSelected)

  const listContainerRef = useRef(null);

  const renderStateValue = useCallback(selected => {
    if (selected.length === 0) {
      return <em style={Styles.placeHolder}> {placeHolder ? placeHolder : 'Select'}</em>;
    }

    return selected.join(', ');
  }, []);

  const handleChange = event => {
      const {
        target: {value},
      } = event;
      setAlreadySelected([])
      let text = typeof value === 'string' ? value.split(',') : value


      if(text?.length === 1 && text[0] === undefined){
        setSelectAll(!selectAll)
      }else {
      setSelectedValue(text);
      
      selectedValues(text);
      if (select?.length !== event.target.value?.length) {
        setSelectAll(false);
        SetSelect(event.target.value);
      }

      if (options?.length === event.target.value?.length) {
        setSelectAll(true);
        SetSelect(event.target.value);
      }

    }
    };

  const handleAllSelect = () => {
    if (selectAll) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  useEffect(() => {
    let temp = [];

    if (selectAll) {
      options?.forEach(element => {
        temp.push(element.label);
      });
      // SetSelect(temp);
      setSelectedValue(temp);
      selectedValues(temp);
    }

    if (
      !selectAll &&
      select?.length - 1 === options?.length
    ) {
      setSelectedValue([]);
      selectedValues(temp);
    }
  }, [selectAll]);

  useState(() => {
    if (isSelected?.length > 0) {
      setSelectedValue(isSelected);
      if (isSelected?.length === options?.length) {
        setSelectAll(true);
      }
    }
  }, [isSelected]);

  const handleTextFiled = useCallback(e => {
    let temp = [...options];
    setSearcTxt(e.target.value);
    let searchdata = temp?.filter(val => {
      return val?.label?.toLowerCase().includes(e.target.value?.toLowerCase());
    });

    setDataListArr(searchdata);
  }, [options]);

  const clearSearch = useCallback(() => {
    setSearcTxt('');
    setDataListArr(options);
  }, [dataListArr]);

  useEffect(() => {
    temp = JSON.parse(JSON.stringify(options ?? []));
    setDataListArr(JSON.parse(JSON.stringify(options)));
  }, [options]);

  useEffect(() => {
    if(reset === 1) {
      SetSelect([]);
      setSelectedValue([]);
      selectedValues([], true);
      setSelectAll(false)
    }
  }, [reset])

  useEffect(() => {
    if(aleradySelected && aleradySelected?.length > 0){
    selectedValues(aleradySelected)
    setSelectedValue(aleradySelected)

    let temp = [];
    aleradySelected.forEach((ele) => {
      temp.push(ele)
    })
    SetSelect(temp);
    if (aleradySelected?.length === options?.length) {
      setSelectAll(true);
    }
    }
  }, [aleradySelected, selectAll])

  return (
    <FormControl sx={{width: width ? width : 200 }} >
      <Select
        multiple
        value={selectedValue}
        onChange={handleChange}
        renderValue={renderStateValue}
        disabled={disabled}
        onClose={clearSearch}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: dropdownWidth || (width ? width : 200),
              whiteSpace: 'normal',
              wordBreak: 'break-word'
            },
          },
        }}
        displayEmpty
        sx={{
          backgroundColor: '#FFFFFF',
          height: '45px', 
          padding: '0px', 
          width: '100%',
          '& .MuiSelect-select': {
            height: '100%', 
            display: 'flex', 
            alignItems: 'center', 
          },
          '& .css-zfrihp-MuiSvgIcon-root-MuiSelect-icon': {
            backgroundColor: '#FFFFFF',
          },
          '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
            width: '62%'
          }
        }}
      >
        {isSearchable ? (
          <ListSubheader style={{paddingLeft: 5, paddingRight: 5}}>
            <SearchTextField
              size="small"
              value={searchtxt}
              autoFocus
              placeholder="Search..."
              fullWidth
              sx={{padding: 0}}
              InputProps={{
                style: {
                  height: 30,
                  fontSize: 11,
                  paddingLeft: 2,
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{padding: 0, marginRight: -2}}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{cursor: 'pointer', padding: 0, margin: 0}}
                    onClick={clearSearch}
                  >
                    <ClearIcon sx={{fontSize: 15}} />
                  </InputAdornment>
                ),
              }}
              onChange={handleTextFiled}
              onKeyDown={e => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        ) : null}
        {dataListArr?.length > 0 && (
          <MenuItem onChange={handleAllSelect} sx={{padding: 0.2, margin: 0, marginBottom: 1}}>
            <StyledCheckbox
              value={'Select All'}
              checked={selectAll}
              inputProps={{'aria-label': 'Select All'}}
              sx={{padding: '0px', margin: '0px',}}
            />
            <ListItemText
              primary={
                <TitleTypography sx={Styles.listItemText}>
                  {selectAll ? 'UnSelect All' : 'Select All'}
                </TitleTypography>
              }
            />
          </MenuItem>
        )}
        {dataListArr?.map(val => (
          <MenuItem key={val.value} value={val.label} sx={{padding: 0.2, margin: 0, marginBottom: 1}}>
            <StyledCheckbox checked={selectedValue.indexOf(val.label) > -1} sx={{padding: '0px', margin: '0px',}} />
            <ListItemText
              primary={
                <TitleTypography sx={Styles.listItemText}>{val?.label}</TitleTypography>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropDown;
