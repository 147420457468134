import * as React from 'react';

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  MonthType,
  SegmentList,
} from '@app/screens/incentive-configuration/types';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandedCell from './ExpandedCell';
import If from '@app/components/If';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {getGrandTotal} from '@app/screens/incentive-configuration/helper';
import {incentiveSelector} from '@app/screens/incentive-configuration/redux/selectors';
import {styles} from './style';
import {useSelector} from 'react-redux';
import { StyledTableCell } from '@app/screens/Styles/style';

interface SalesTransferTableProps {
  showDisclaimer: any;
  isEmpty: any;
  onUpdateHandler: (data: any) => any;
  salesTransferAllData: any;
}

export const SalesTransferTable: React.FC<SalesTransferTableProps> = ({
  isEmpty,
  onUpdateHandler,
  salesTransferAllData,
}) => {
  const [unmount, setUnmount] = React.useState(true);
  const quarterList: any = useSelector(
    incentiveSelector.getselectedQuarterListData(),
  );

  if (isEmpty) {
    return (
      <Box
        sx={{color: '#322b7c', fontSize: 12, fontWeight: 500, marginLeft: 5}}>
        No Record Found
      </Box>
    );
  }

  return (
    <Box sx={{p: 2}}>
      {
        <>
          <TableContainer
            sx={{
              maxHeight: 'calc(100vh - 360px)',
              // minHeight: '45vh',
            }}>
            <Table stickyHeader aria-label="sticky table" size="medium">
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox" />
                  {salesTransferAllData.isProductView ? (
                    <>
                      <StyledTableCell
                        style={{...styles.groupHeder, paddingLeft: 13}}>
                        Product
                      </StyledTableCell>
                      <StyledTableCell
                        style={{...styles.groupHeder, paddingLeft: 13}}>
                        Pack
                      </StyledTableCell>
                      <StyledTableCell
                        style={{...styles.groupHeder, paddingLeft: 13}}>
                        Code
                      </StyledTableCell>
                    </>
                  ) : (
                    <>
                      <StyledTableCell
                        style={{...styles.groupHeder, paddingLeft: 13}}>
                        Group
                      </StyledTableCell>
                      <StyledTableCell align="left" style={styles.tableHeader}>
                        INC%
                      </StyledTableCell>
                    </>
                  )}
                  {quarterList?.month?.map((month: any, index: any) => {
                    const index_key = index.toString();
                    return (
                      <StyledTableCell
                        key={index_key}
                        align="left"
                        style={styles.tableHeader}>
                        {month?.name}
                        <Typography
                          sx={{
                            fontSize: '9px',
                            color: '#1C1939',
                            fontWeight: 600,
                          }}>
                          {salesTransferAllData.isProductView
                            ? '(In Qty)'
                            : '(In Rs)'}
                        </Typography>
                      </StyledTableCell>
                    );
                  })}
                  <StyledTableCell
                    align="left"
                    style={{
                      ...styles.totalHeader,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    }}>
                    Total Sale
                    <Typography
                      sx={{
                        fontSize: '9px',
                        color: '#1C1939',
                        fontWeight: 600,
                      }}>
                      {salesTransferAllData.isProductView
                        ? '(In Qty)'
                        : '(In Rs)'}
                    </Typography>
                  </StyledTableCell>
                  {salesTransferAllData.isProductView ? null : (
                    <StyledTableCell align="left" style={styles.tableHeader}>
                      Incentive
                      <Typography
                        sx={{
                          fontSize: '9px',
                          color: '#1C1939',
                          fontWeight: 600,
                        }}>
                        {salesTransferAllData.isProductView
                          ? '(In Qty)'
                          : '(In Rs)'}
                      </Typography>
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="left" style={styles.remarkHeader}>
                    Remarks
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {!isEmpty ? (
                <TableBody>
                  {salesTransferAllData?.segmentList?.map(
                    (row: SegmentList) => (
                      <ExpandableTableRow
                        key={row.id}
                        expandComponent={
                          salesTransferAllData.isProductView
                            ? row?.subProduct
                            : row?.subSegment
                        }
                        unmount={unmount}
                        onUpdateHandler={onUpdateHandler}
                        salesTransferAllData={salesTransferAllData}
                        onUnMount={(val: boolean) => {
                          setUnmount(val);
                        }}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{
                            fontWeight: 500,
                            paddingLeft: 12,
                            paddingRight: 12,
                          }}>
                          {row?.name}
                        </StyledTableCell>

                        {salesTransferAllData.isProductView ? (
                          <>
                            <StyledTableCell
                              style={{fontWeight: 700}}></StyledTableCell>
                            <StyledTableCell
                              style={{fontWeight: 700}}></StyledTableCell>
                          </>
                        ) : (
                          <StyledTableCell
                            style={{fontWeight: 700}}></StyledTableCell>
                        )}

                        <StyledTableCell>
                          <div
                            style={{
                              backgroundColor: '#e9e9e9',
                              borderRadius: 5,
                              height: 26,
                              minWidth: 85,
                              textAlign: 'right',
                              padding: 5,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              fontWeight: 500,
                            }}>
                            {Number(row.month1).toFixed(0)}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div
                            style={{
                              backgroundColor: '#e9e9e9',
                              borderRadius: 5,
                              height: 26,
                              minWidth: 85,
                              textAlign: 'right',
                              padding: 5,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              fontWeight: 500,
                            }}>
                            {Number(row.month2).toFixed(0)}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div
                            style={{
                              backgroundColor: '#e9e9e9',
                              borderRadius: 5,
                              height: 26,
                              minWidth: 85,
                              textAlign: 'right',
                              padding: 5,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              fontWeight: 500,
                            }}>
                            {Number(row.month3).toFixed(0)}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div
                            style={{
                              backgroundColor: '#e9e9e9',
                              borderRadius: 5,
                              height: 26,
                              minWidth: 80,
                              textAlign: 'right',
                              padding: 5,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              fontWeight: 500,
                            }}>
                            {(
                              Number(row.month1) +
                              Number(row.month2) +
                              Number(row.month3)
                            ).toFixed(0)}
                          </div>
                        </StyledTableCell>
                        {salesTransferAllData.isProductView ? null : (
                          <StyledTableCell>
                            <div
                              style={{
                                backgroundColor: '#e9e9e9',
                                borderRadius: 5,
                                height: 26,
                                minWidth: 80,
                                textAlign: 'right',
                                padding: 5,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                fontWeight: 500,
                              }}>
                              {Number(row.incentiveAmount).toFixed(2)}
                            </div>
                          </StyledTableCell>
                        )}
                        <StyledTableCell>
                          <div style={{backgroundColor: '#e9e9e9'}}></div>
                        </StyledTableCell>
                      </ExpandableTableRow>
                    ),
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  <StyledTableCell colSpan={3}>
                    <div>No Record Found</div>
                  </StyledTableCell>
                </TableBody>
              )}
              <TableFooter
                style={{left: 0, bottom: 0, zIndex: 2, position: 'sticky'}}>
                <TableRow style={{backgroundColor: '#2fb4ff'}}>
                  <StyledTableCell padding="checkbox" />
                  <StyledTableCell
                    colSpan={2}
                    style={{
                      color: '#ffffff',
                      fontSize: 12,
                      fontWeight: 700,
                    }}>
                    Grand total
                  </StyledTableCell>
                  {salesTransferAllData.isProductView ? (
                    <StyledTableCell style={{fontWeight: 700}} />
                  ) : null}
                  <StyledTableCell align="left">
                    <div
                      style={{
                        backgroundColor: '#94d8ff',
                        borderRadius: 5,
                        height: 26,
                        minWidth: 90,
                        textAlign: 'right',
                        padding: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontWeight: 500,
                        color: '#322b7c',
                      }}>
                      {getGrandTotal(
                        salesTransferAllData?.segmentList,
                        MonthType.month1,
                      ).toFixed(0)}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div
                      style={{
                        backgroundColor: '#94d8ff',
                        borderRadius: 5,
                        height: 26,
                        minWidth: 90,
                        textAlign: 'right',
                        padding: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontWeight: 500,
                        color: '#322b7c',
                      }}>
                      {getGrandTotal(
                        salesTransferAllData?.segmentList,
                        MonthType.month2,
                      ).toFixed(0)}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div
                      style={{
                        backgroundColor: '#94d8ff',
                        borderRadius: 5,
                        height: 26,
                        minWidth: 90,
                        textAlign: 'right',
                        padding: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontWeight: 500,
                        color: '#322b7c',
                      }}>
                      {getGrandTotal(
                        salesTransferAllData?.segmentList,
                        MonthType.month3,
                      ).toFixed(0)}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div
                      style={{
                        backgroundColor: '#94d8ff',
                        borderRadius: 5,
                        height: 26,
                        minWidth: 90,
                        textAlign: 'right',
                        padding: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontWeight: 500,
                        color: '#322b7c',
                      }}>
                      {getGrandTotal(
                        salesTransferAllData?.segmentList,
                        MonthType.month1,
                      ) +
                        getGrandTotal(
                          salesTransferAllData?.segmentList,
                          MonthType.month2,
                        ) +
                        getGrandTotal(
                          salesTransferAllData?.segmentList,
                          MonthType.month3,
                        )}
                    </div>
                  </StyledTableCell>
                  {salesTransferAllData.isProductView ? null : (
                    <StyledTableCell align="left">
                      <div
                        style={{
                          backgroundColor: '#94d8ff',
                          borderRadius: 5,
                          height: 26,
                          minWidth: 90,
                          textAlign: 'right',
                          padding: 5,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          fontWeight: 500,
                          color: '#322b7c',
                        }}>
                        {getGrandTotal(
                          salesTransferAllData?.segmentList,
                          MonthType.incentive,
                        ).toFixed(2)}
                      </div>
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="left">
                    <div
                      style={{
                        borderRadius: 5,
                        height: 26,
                        minWidth: 80,
                        width: 80,
                        textAlign: 'right',
                        padding: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontWeight: 500,
                        color: '#322b7c',
                      }}></div>
                  </StyledTableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <If condition={salesTransferAllData?.showDisclaimer}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 10,
              }}>
              <div>
                <span
                  style={{
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    fontSize: 12,
                    color: 'red',
                  }}>
                  Disclaimer -
                </span>
                <span
                  style={{
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    fontSize: 12,
                    color: 'red',
                  }}>
                  {salesTransferAllData?.disclaimer}
                </span>
              </div>
            </div>
          </If>
        </>
      }
    </Box>
  );
};

const ExpandableTableRow = (props: any) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const getChild = React.useMemo(() => {
    let txt = props?.salesTransferAllData?.isProductView
      ? 'subProduct'
      : 'subSegment';
    return (
      <React.Fragment>
        {props?.expandComponent?.map(
          (row: Omit<SegmentList, typeof txt>, index: any) => {
            const index_key = index?.toString();
            return (
              <ExpandedCell
                key={index_key}
                row={row}
                onUnMount={props?.onUnMount}
                unmount={props?.unmount}
                onUpdateHandler={props?.onUpdateHandler}
              />
            );
          },
        )}
      </React.Fragment>
    );
  }, [open, props]);
  return (
    <>
      <TableRow
        {...props.otherProps}
        style={{backgroundColor: 'rgba(213, 226, 231, 0.32)'}}>
        <StyledTableCell padding="checkbox">
          {props?.expandComponent?.length > 0 ? (
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              <If condition={isExpanded}>
                <RemoveCircleOutlineIcon style={{color: '#322b7c'}} />
              </If>
              <If condition={!isExpanded}>
                <AddCircleOutlineIcon style={{color: '#322b7c'}} />
              </If>
            </IconButton>
          ) : null}
        </StyledTableCell>
        {props.children}
      </TableRow>
      {isExpanded && getChild}
    </>
  );
};
