import {TOAST_MESSAGES} from '@app/common/constants';
import {ToasterService} from '.';
import NetworkService from './network/NetworkService';
import {ToastType} from './toaster';

export const downloadExcelFile = async (apiPath: string, params: any) => {
  const response = await NetworkService.post(apiPath, params, {});

  if (response.status !== 200) {
    ToasterService.showToaster(TOAST_MESSAGES.DOWNLOAD_FAILED, ToastType.ERROR);
  } else {
    const fileUrl = response?.data;
    if (fileUrl) {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = fileUrl;
      a.download = 'file.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      console.error('No URL provided for download');
    }
  }
};
